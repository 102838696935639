.leaderboard-container {
    min-height: 100vh;
    background-image: url('../img/Background.png');
    background-size: cover; /* Changed from contain to cover for better responsiveness */
    padding: 2rem;
  }
  
  .leaderboard-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 1rem; /* Added padding for smaller screens */
  }
  
  .leaderboard-title {
    color: #ffd700;
    font-size: 2rem; /* Adjusted for better scaling */
    text-align: center;
    margin-bottom: 2rem; /* Reduced margin for smaller screens */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem; /* Adjusted gap */
  }
  
  .podium-section {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 1rem;
    margin-bottom: 3rem;
    flex-wrap: wrap; /* Allows wrapping for smaller screens */
  }
  
  .podium-place {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Adjusted width for smaller screens */
    max-width: 200px;
  }
  
  .podium-avatar {
    border-radius: 50%;
    margin-bottom: 0.5rem;
  }
  
  .first-place .podium-avatar {
    width: 100px; /* Reduced size for responsiveness */
    height: 100px;
    border: 4px solid #ffd700;
  }
  
  .second-place .podium-avatar {
    width: 90px;
    height: 90px;
    border: 4px solid #C0C0C0;
  }
  
  .third-place .podium-avatar {
    width: 90px;
    height: 90px;
    border: 4px solid #CD7F32;
  }
  
  .podium-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 140px; /* Adjusted width */
  }
  
  .first-place .podium-block {
    height: 150px; /* Reduced height for responsiveness */
    background-color: #292929;
  }
  
  .second-place .podium-block {
    height: 130px;
    background-color: #292929;
  }
  
  .third-place .podium-block {
    height: 110px;
    background-color: #292929;
  }
  
  .podium-name {
    color: white;
    font-weight: bold;
    text-align: center;
    margin: 0.5rem 0;
    font-size: 0.9rem; /* Adjusted font size for smaller screens */
  }
  
  .podium-amount {
    color: #4caf50;
    font-weight: bold;
  }
  
  .crown-icon {
    color: #ffd700;
    margin-bottom: 0.5rem;
  }
  
  .players-list {
    background-color: #292929;
    border-radius: 8px;
    padding: 1rem;
  }
  
  .player-row {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #383838;
    transition: background-color 0.2s;
    flex-wrap: wrap; /* Allows wrapping for smaller screens */
  }
  
  .player-row:last-child {
    border-bottom: none;
  }
  
  .player-row:hover {
    background-color: #323232;
  }
  
  .player-rank {
    color: #888;
    font-weight: bold;
    width: 2rem;
    text-align: center; /* Added alignment */
  }
  
  .player-avatar {
    width: 40px; /* Reduced size for responsiveness */
    height: 40px;
    border-radius: 50%;
    margin: 0 1rem;
    border: 2px solid #383838;
  }
  
  .player-name {
    color: white;
    flex: 1;
    font-size: 0.9rem; /* Adjusted font size */
  }
  
  .player-amount {
    color: #4caf50;
    font-weight: bold;
    font-size: 0.9rem; /* Adjusted font size */
  }
  
  .loading {
    color: #ffd700;
    text-align: center;
    font-size: 1.2rem; /* Adjusted font size */
    padding: 2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .leaderboard-title {
      font-size: 1.5rem;
    }
  
    .podium-section {
      gap: 0.5rem;
      display: flex;
      flex-direction: column; /* Align items vertically */
      justify-content: flex-start; /* Align items at the start vertically (optional) */
      align-items: center; /* Center items horizontally */
    }
    .first-place {
        order: 1; /* First in visual order */
      }
      
      .second-place {
        order: 2; /* Second in visual order */
      }
      
      .third-place {
        order: 3; /* Third in visual order */
      }
  
    .podium-place {
      max-width: 150px;
    }
  
    .podium-block {
      width: 120px;
    }
  
    .players-list {
      padding: 0.5rem;
    }
  
    .player-row {
      padding: 0.5rem;
    }
  
    .player-avatar {
      width: 32px;
      height: 32px;
    }
  
    .player-name,
    .player-amount {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .leaderboard-title {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  
    .podium-section {
      flex-direction: column; /* Stack podiums vertically */
      align-items: center;
    }
  
    .podium-place {
      max-width: 100%;
    }
  
    .podium-block {
      width: 100px;
      height: auto;
      padding: 0.5rem;
    }
  
    .player-row {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .player-avatar {
      margin: 0 0 0.5rem 0;
    }
  }

  .footer_text {
    text-align: center;
    color: white;
    font-size: 24px;
    padding-bottom: 40px;
}