:root {
    --Primary-color :#2596be
    }
    /* #C85858 */
.Navbar {
    background-color: #292929;
    display: grid;
    grid-template-columns: 9% 9% 9% 4.5% 9% 9% 9% 9% 13.5% 9% 9%;
    grid-template-rows: 100%;
    grid-gap: 1px;

}
@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/Poppins/Poppins-Regular.ttf) format('truetype');
}

.logga_text{
font-family: 'Poppins';
}


.nav_button {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}



.hover_box::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: var(--Primary-color);
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;

}

.hover_box:hover::before {
    transform-origin: left;
    transform: scaleX(1);
}

.home {
    text-decoration: none;
    position: relative;
    text-align: center;
    grid-column-start: 5;
    grid-column-end: 6;
    height: 100%;
    width: 100%;
}

.Nav_text {
    text-transform: uppercase;
    color: var(--Primary-color);
}

.store {
    text-decoration: none;
    position: relative;
    text-align: center;
    grid-column-start: 8;
    grid-column-end: 9;
    height: 100%;
    width: 100%;
}

.Leaderboard {
    text-decoration: none;
    position: relative;
    text-align: center;
    grid-column-start: 6;
    grid-column-end: 8;
    height: 100%;
    width: 100%;
}

.no_text_deocoration {
    text-decoration: none;
}

.logga {
    padding-left: 120%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: 2;
    font-size: 42px;
    text-decoration: none;
}


.socials {
    text-decoration: none;
    grid-column-start: 10;
    grid-column-end: 11;
    padding-left: 60%;
    height: 100%;
    width: 99%;
    display: flex;
    transition: all 0.2s ease-in-out;
}

.social_box {

    padding-top: 30%;

}

.social_buttons {

    color: var(--Primary-color);
    text-align: center;
    display: flex;
    align-items: center;
    height: 50%;
    padding-top: 50%;
    text-decoration: none;
    padding-right: 20px;
}

.icon {
    fill: var(--Primary-color);
    height: 30px;
    width: 30px;

}

.icon:hover {
    transform: scaleX(1.1);

}


/* Diffrent Screen sizes */



/* Laptop 1440 * 900 */
@media screen and (max-width: 1680px) {
    .Navbar {
        background-color: #292929;
        display: grid;
        grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
        grid-template-rows: 100%;
        grid-gap: 1px;
    }

    .logga {
        padding-left: 60%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column-start: 1;
        grid-column-end: 2;
        font-size: 32px;
        text-decoration: none;
    }

    .home {
        text-decoration: none;
        position: relative;
        text-align: center;
        grid-column-start: 3;
        grid-column-end: 4;
        height: 100%;
        width: 100%;
    }

    .store {
        text-decoration: none;
        position: relative;
        text-align: center;
        grid-column-start: 6;
        grid-column-end: 7;
        height: 100%;
        width: 100%;
    }
    
    .Leaderboard {
        text-decoration: none;
        position: relative;
        text-align: center;
        grid-column-start: 4;
        grid-column-end: 6;
        height: 100%;
        width: 100%;
    }

    .Nav_text {
        text-transform: uppercase;
        color: var(--Primary-color);
        font-size: 24px;
    }

    .socials {
        text-decoration: none;
        grid-column-start: 7;
        grid-column-end: 8;
        padding-left: 60%;
        height: 100%;
        width: 99%;
        display: flex;
        transition: all 0.2s ease-in-out;
    }
}

@media screen and (min-width: 1439px) and (max-width: 1680px) {
    .Nav_text {
        font-size: 30px;
    }
}

@media screen and (min-width: 1023px) and (max-width: 1025px) {
    .Navbar {
        grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6%;
    }

    .coins {
        width: 60px;
        height: 60px;
    }

    .logga {
        padding-left: 10%;
        grid-column-start: 1;
        grid-column-end: 2;
        font-size: 20px;

    }

    .home {
        grid-column-start: 2;
        grid-column-end: 3;
    }

    .store {
        grid-column-start: 3;
        grid-column-end: 4;
    }

    .Leaderboard {
        grid-column-start: 4;
        grid-column-end: 6;
    }

    .icon {
        width: 20px;
        height: 20px;
    }

    .socials {
        grid-column-start: 6;
        grid-column-end: 7;
        padding-left: 0px;
    }
}

@media screen and (min-width: 100px) and (max-width: 828px) {

    .Navbar {
        grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6%;
        grid-template-rows: 40px 40px 40px;
        height: 100px;
    }

    .Nav_text {
        font-size: 12px;
    }

    .coins {
        width: 60px;
        height: 60px;
    }

    .logga {
        padding-left: 90%;
        grid-column-start: 1;
        grid-column-end: 4;
        font-size: 24px;
    }

    .home {
        grid-row: 2;
        grid-column-start: 2;
        grid-column-end: 3;
    }

    .store {
        grid-row: 2;
        grid-column-start: 5;
        grid-column-end: 6;
    }

    .Leaderboard {
        grid-row: 2;
        grid-column-start: 3;
        grid-column-end: 5;
    }

    .hover_box::before {
        width: 0%;
        background-color: black;
    }

    .icon {
        width: 40px;
        height: 40px;
        padding-right: 20px;
    }

    .socials {

    }
}

/* Mobile Dropdown Styles */
@media screen and (max-width: 828px) {

    .Navbar {
        height: 50px;
    }
    .mobile-dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column-start: 6;
        grid-column-end: 7;
        grid-row: 1;
        padding-left: 15px;
        padding-top: 5px;
        cursor: pointer;
    }

    .mobile-dropdown-toggle svg {
        font-size: 48px !important; /* Significantly larger hamburger icon */
    }

    .mobile-dropdown-menu {
        position: absolute;
        top: 50px; /* Adjust based on your navbar height */
        left: 0;
        width: 100%;
        background-color: #292929;
        display: flex;
        flex-direction: column;
        z-index: 1000;
        box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    }

    .mobile-dropdown-item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        padding: 20px 0;
        text-decoration: none;
        border-bottom: 1px solid rgba(37, 150, 190, 0.3);
        transition: background-color 0.3s ease;
    }

    .mobile-dropdown-item:hover {
        background-color: rgba(37, 150, 190, 0.1);
    }

    .mobile-dropdown-item .Nav_text {
        font-size: 20px;
        color: var(--Primary-color);
    }

    .mobile-dropdown-item svg {
        transform: scale(1.2);
    }

    .mobile-social-links {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        margin: 20px 0;
        padding: 10px;
        background-color: rgba(37, 150, 190, 0.05);
    }

    .mobile-social-links .icon {
        transform: scale(1.3);
        transition: transform 0.3s ease;
    }

    .mobile-social-links .icon:hover {
        transform: scale(1.4);
    }

    .home, .Leaderboard, .store, .socials {
        display: none;
    }
}

/* Ensure other screen sizes show full navbar */
@media screen and (min-width: 829px) {
    .mobile-dropdown-toggle, 
    .mobile-dropdown-menu {
        display: none !important;
    }
}