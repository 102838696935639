:root {
    --Primary-color :#2596be
    }
    /* #C85858 */
@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/Poppins/Poppins-Regular.ttf) format('truetype');
}

.outer_div {
    padding: 50px 200px 200px 200px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 40px;
    place-items: center;
}

.item_box {
    background-color: #292929;
    width: 300px;
    height: 360px;
    position: relative;
    text-align: center;
    align-items: center;
    padding: 20px;
    transition: all 0.2s ease-in-out;
    border-radius: 15px;
}

.item_box:hover {
    /* background-color: var(--Primary-color); */
    transform: scale(1.1);
}


.claim_box {
    position: absolute;
    bottom: 15px;
    background-color: #292929;
    text-align: center;
    margin-left: 100px;
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-radius: 10px;
    border-color: var(--Primary-color);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.claim_box:hover {
    transform: scale(1.1);
}

.all_offers_img {
    height: 150px;
    width: 200px;
}


.welcome_banner {
    width: 100%;
    height: 800px;
    text-align: center;
    background-image: url("../img/Roulet_main.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 800px 0px;
    background-color: black;
}

.welcome_text {
    padding-top: 200px;
    padding-left: 100px;
    color: white;
    font-family: 'Poppins';
    text-align: left;
    font-size: 105px;
    font-weight: 800;
}

.hstjorven_text {
    color: var(--Primary-color);
    font-family: 'Poppins';
    text-align: left;
    font-size: 97px;
    font-weight: 800;
}

.claim_button_other {
    text-decoration: none;
    color: red;
}

.button_text_other {
    font-size: 1.2em;
    color: var(--Primary-color);
}

.Other_offer_text {
    padding-top: 200px;
    text-align: center;
    font-size: 60px;
    font-family: 'Poppins';
    color: white;
}

.offer_text {
    font-size: 15px;
    color: white;
}

.best_offer_box {
    width: 100%;
    min-height: 300px;
    position: relative;
    padding: 20px;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    color: white;

}

.claim_box_best {
    background-color: #292929;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 0px;
    margin: 0 auto;
    margin-top: 70px;

}

.claim_button_bestoffer {
    border-style: solid;
    border-radius: 10px;
    padding: 10px 25px 10px 25px;
    border-color: var(--Primary-color);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.claim_button_bestoffer:hover {
    transform: scale(1.2);
    /* background-color: var(--Primary-color); */
    /* color: #292929; */

}


.button_text {
    font-size: 36px;
    color: var(--Primary-color);
}

.img_div {
    position: relative;
    margin: auto;
    width: 800px;
    height: 600px;
    text-align: center;
    background-color: #292929;
    border-radius: 15px;
    padding-top: 30px;
}

.Best_offer_text {
    padding-top: 40px;
    padding-bottom: 40px;
    font-family: 'Poppins';
    text-align: center;
    font-size: 60px;
    color: white;
}

.offer_text_best {
    font-size: 30px;
    text-align: center;
}

.Offers_background_div {
    background-image: url('../img/Background.png');
    background-size: contain;
}

.MGA {
    position: absolute;
    text-align: right;
    /* border-style: solid;
    border-radius: 10px;
    padding: 5px 5px 5px 5px;
    border-color: var(--Primary-color); */
    top: 5px;
    right: 5px;
}
.CUR {
    position: absolute;
    text-align: right;
    /* border-style: solid;
    border-radius: 10px;
    padding: 5px 5px 5px 5px;
    border-color: var(--Primary-color); */
    top: 5px;
    right: 5px;
}
.CR {
    position: absolute;
    text-align: right;
    /* border-style: solid;
    border-radius: 10px;
    padding: 5px 5px 5px 5px;
    border-color: var(--Primary-color); */
    top: 5px;
    right: 5px;
}
.ES {
    position: absolute;
    text-align: right;
    /* border-style: solid;
    border-radius: 10px;
    padding: 5px 5px 5px 5px;
    border-color: var(--Primary-color); */
    top: 5px;
    right: 5px;
}

.MGA_text {
    color: var(--Primary-color);
    text-transform: uppercase;
}

.MGA_info_text_first {
    padding-top: 10px;
    padding-right: 20px;
    text-align: right;
    color: white;
    font-size: 24px;
}
.MGA_info_text_second{
    padding-top: 10px;
    padding-left: 10px;
    color: white;
    font-size: 24px;
}
.first_box{
    display: inline-block;
    margin: 0 auto;
    width: 50%;
}
.second_box{
    display: inline-block;
    margin: 0 auto;
    width: 50%;
}

.footer_text {
    text-align: center;
    color: white;
    font-size: 24px;
    padding-bottom: 40px;
}

.twitch {
    width: 420px;
    height: 310px;
    background-color: #292929;
    position: fixed;
    bottom: 0px;
    right: 0px;
    border-radius: 15px;
    text-align: center;
    color: white;
    font-family: 'Poppins';
}

.youtube_container {
    width: 1816px;
    height: 100%;
    margin: 0 auto;
    align-items: center;
    border-style: solid;
    border-width: 5px;
    border-color: var(--Primary-color);
    border-radius: 5px;
}

.youtube_iframe {
    width: 450px;
    height: 315px;
}

.youtube_div {
    display: inline-block;
}

.video_text {
    text-align: center;
    color: white;
    font-size: 30px;
    padding-bottom: 20px;
}

.outer_youtube_box {
    padding-bottom: 100px;
}
.new_casino{
    background-color: red;
    position: relative;
    transform: rotate(315deg);
    left: -10px;
    top: 8px;
    position: absolute;
    background-color: red;
    color: white;
    padding: 5px;
    transform-origin: 100% 0%;
}
.new_casino_best{
    font-size: 25px;
    background-color: red;
    position: relative;
    transform: rotate(315deg);
    left: -10px;
    top: 8px;
    position: absolute;
    background-color: red;
    color: white;
    padding: 10px;
    transform-origin: 100% 0%;
}

/* Diffrent Screen sizes */




/* Laptop 1440 * 900 */
@media screen and (min-width: 1025px) and (max-width: 1440px) {
    .outer_div {
        padding: 50px 100px 80px 100px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 40px;
        place-items: center;
    }

    .item_box {
        background-color: #292929;
        width: 250px;
        height: 300px;
        position: relative;
        text-align: center;
        align-items: center;
        padding: 10px;
        transition: all 0.2s ease-in-out;
        border-radius: 15px;
    }

    .offer_text {
        font-size: 12px;
        color: white;
    }

    .claim_box {
        position: absolute;
        bottom: 8px;
        background-color: #292929;
        text-align: center;
        margin-left: 70px;
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-style: solid;
        border-radius: 10px;
        border-color: var(--Primary-color);
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

    .welcome_banner {
        background-position: 500px 0px;
    }

    .welcome_text {
        padding-left: 40px;
        font-size: 75px;
    }

    .hstjorven_text {
        font-size: 75px;
    }

    .youtube_container {
        width: 908px;
        height: 100%;
        margin: 0 auto;
    }

    .youtube_iframe {
        width: 450px;
        height: 315px;
    }
}

/* Laptop 1680 */
@media screen and (min-width: 1439px) and (max-width: 1680px) {
    .outer_div {
        padding: 50px 100px 80px 100px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 30px;

    }

    .item_box {
        width: 240px;
        height: 360px;
        padding: 30px;
    }

    .claim_box {
        bottom: 8px;
        margin-left: 70px;

    }

    .welcome_banner {
        background-position: 700px 0px;
    }

    .welcome_text {
        padding-left: 60px;
    }


    .youtube_container {
        width: 910px;
        height: 100%;
        margin: 0 auto;
    }

    .youtube_iframe {
        width: 450px;
        height: 315px;
    }
}

/* phone 1440 * 900 */
@media screen and (min-width: 1023px) and (max-width: 1025px) {
    .outer_div {
        padding: 50px 100px 80px 100px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 40px;

    }

    .item_box {
        width: 300px;
        height: 380px;
        padding: 10px;
    }

    .claim_box {
        bottom: 8px;
        margin-left: 100px;
    }

    .welcome_banner {
        height: 500px;
        background-position: 400px 0px;
    }

    .welcome_text {
        padding-left: 40px;
        font-size: 55px;
    }

    .hstjorven_text {
        font-size: 55px;
    }

    .youtube_container {
        width: 910px;
        height: 100%;
        margin: 0 auto;
    }

    .youtube_iframe {
        width: 450px;
        height: 315px;
    }

}

@media screen and (min-width: 100px) and (max-width: 828px) {
    .welcome_banner {
        height: 200px;
        background-position: 200px 0px;
    }

    .welcome_text {
        padding-top: 50px;
        padding-left: 20px;
        font-size: 24px;
    }

    .hstjorven_text {
        font-size: 24px;
    }

    /* Best offer style */
    .img_div {
        width: 350px;
        height: 350px;
    }

    .Best_offer_img {
        height: 150px;
        width: 250px;
    }


    .claim_box_best {
        width: 70px;
        height: 30px;
        margin-top: 40px;

    }

    .Best_offer_text {
        padding-top: 20px;
        padding-bottom: 40px;
        font-size: 30px;
    }


    .offer_text_best {
        font-size: 20px;
    }

    .claim_button_other {
        text-decoration: none;
        color: red;
    }

    .button_text_other {
        font-size: 1.2em;
        color: var(--Primary-color);
    }

    .Other_offer_text {
        padding-top: 200px;
        text-align: center;
        font-size: 60px;
        font-family: 'Poppins';
        color: white;
    }

    /* Other offer style */
    .claim_button_other {
        text-decoration: none;
        color: red;
    }

    .button_text_other {
        font-size: 1.2em;
        color: var(--Primary-color);
    }

    .Other_offer_text {
        padding-top: 50px;
        font-size: 30px;
    }

    /* MGA STYLE */
    .MGA {
        position: absolute;
        text-align: right;
        top: 5px;
        right: 5px;
    }


    .MGA_info_text {
        padding-top: 10px;
        font-size: 20px;
    }

    /* Every other offer box */
    .outer_div {
        padding: 50px 20% 200px 20%;
        grid-template-columns: repeat(1, 1fr);
    }

    .all_offers_img {
        height: 80px;
        width: 80px;
    }

    .item_box {
        width: 200px;
        height: 320px;
    }

    .claim_box {
        bottom: 10px;
        margin-left: 50px;
        width: 100px;
        height: 40px;
    }

    .claim_box:hover {
        transform: scale(1.1);
    }

    .offer_text {
        font-size: 14px;
    }

    /* Youtube Styling */

    .youtube_container {
        width: 380px;
        height: 100%;
        margin: 0 auto;
    }

    .youtube_iframe {
        width: 375px;
        height: 300px;
    }
}

@media screen and (min-width: 2560px) and (max-width: 3440px) {

    /* Every other offer box */
    .outer_div {
        padding: 50px 20% 200px 20%;
        grid-template-columns: repeat(5, 1fr);
    }

    .all_offers_img {
        height: 150px;
        width: 150px;
    }

    .item_box {
        width: 300px;
        height: 350px;
    }

    .claim_box {
        bottom: 10px;
        margin-left: 100px;
        width: 100px;
        height: 40px;
    }

    .claim_box:hover {
        transform: scale(1.1);
    }

    .offer_text {
        font-size: 16px;
    }

    /* Youtube Styling */

    .youtube_container {
        width: 2558px;
        height: 100%;
        margin: 0 auto;
    }

    .youtube_iframe {
        width: 635px;
        height: 400px;
    }
}

.Temp_button{
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
}
.Temp_text{
    color: white;
}